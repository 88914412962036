<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa taloyhtiötä</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()">Takaisin</v-btn>
    </div>

    <!-- Loader -->
    <full-page-loader
      v-if="loading"
      text="Haetaan kiinteistöä/taloyhtiötä..."
      class="full-page-loader"
    ></full-page-loader>

    <condominium-form
      v-if="!loading"
      save-btn-text="Tallenna"
      @sendcondominium="submit"
    ></condominium-form>
  </div>
</template>

<script>
import CondominiumForm from "../../components/Condominium/CondominiumForm";
import mixins from "../../mixins/mixins";
import { mapState, mapActions, mapMutations } from "vuex";
import FullPageLoader from "@/components/FullPageLoader";

export default {
  title: "Muokkaa taloyhtiötä",

  components: {
    CondominiumForm,
    FullPageLoader,
  },

  mixins: [mixins],

  computed: {
    ...mapState("condominium", ["currentCondominium", "loading"]),
  },

  created() {
    this.setLoading(true);
    this.getOneCondominium(this.$route.params.id);
  },

  methods: {
    ...mapActions("condominium", ["getOneCondominium", "editCondominium"]),
    ...mapMutations("condominium", ["setLoading"]),

    async submit() {
      try {
        let condominium = this.currentCondominium;
        delete condominium.createdBy;
        delete condominium.createdAt;
        delete condominium.updatedAt;
        delete condominium.__v;
        delete condominium._id;
        delete condominium.id;

        const url = `/api/v1/condominium/update/${this.$route.params.id}`;

        await this.editCondominium({ url, condominium });
        this.$router.push("/condominiums");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
